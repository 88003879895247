"use client";

import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { generateGoogleMapsLink, getAddressAsString } from "helpers/address";
import { getCurrencyAmountForDisplay } from "helpers/currency";
import { formatDate } from "helpers/date";
import { getCurrencySymbol } from "helpers/helpers";
import { createMarkup, getHtmlFromRaw } from "helpers/string";
import { getDoesObjectHaveValue } from "helpers/utils";
import type { Address } from "types/general";
import type { Client } from "types/model/client";
import { FieldType } from "types/model/field";
import type { FieldData } from "types/model/field-data";

export const FieldValue = ({
  fieldDataItem,
  client
}: {
  fieldDataItem: FieldData;
  client: Client;
}) => {
  if (fieldDataItem.dataType === "ref") {
    return <>{fieldDataItem.valueRef?.name}</>;
  } else if (fieldDataItem.dataType === "refArray") {
    return (
      <>{fieldDataItem.valueRefArray?.map(item => item.name).join(", ")}</>
    );
  } else if (fieldDataItem.dataType === "venueRef") {
    return (
      <span>
        {fieldDataItem.valueRefVenue?.name}
        {getDoesObjectHaveValue(
          fieldDataItem.valueRefVenue?.address as Address
        ) && (
          <span className="block">
            {getAddressAsString(
              fieldDataItem.valueRefVenue?.address as Address
            )}{" "}
            [
            <a
              href={generateGoogleMapsLink(
                fieldDataItem.valueRefVenue?.address as Address,
                client,
                fieldDataItem.valueRefVenue?.name
              )}
              className="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none"
              rel="noopener noreferrer"
              target="_blank"
            >
              Map
            </a>
            ]
          </span>
        )}
      </span>
    );
  } else if (fieldDataItem.dataType === "boolean") {
    return <>{fieldDataItem.value ? "Yes" : "No"}</>;
  } else if (fieldDataItem.field.type === FieldType.Textarea) {
    return (
      <div
        className="prose prose-sm"
        dangerouslySetInnerHTML={createMarkup(
          getHtmlFromRaw(fieldDataItem.value)
        )}
      ></div>
    );
  } else if (
    fieldDataItem.dataType === "number" &&
    fieldDataItem.field.type === FieldType.Currency
  ) {
    return (
      <>{`${getCurrencySymbol(client.currency)}${getCurrencyAmountForDisplay(
        fieldDataItem.value,
        client.currency
      ).toFixed(2)}`}</>
    );
  } else if (fieldDataItem.dataType === "date") {
    return (
      <>{formatDate(fieldDataItem.value, client.dateFormat, client.timeZone)}</>
    );
  } else if (fieldDataItem.dataType === "files") {
    return (
      <ul role="list" className="space-y-2">
        {fieldDataItem.value.map(
          (file: {
            _id: string;
            originalname: string;
            isAuthenticated: boolean;
            path: string;
          }) => (
            <li
              key={file._id}
              className="flex items-center justify-between text-sm"
            >
              <div className="flex items-center gap-1">
                <a
                  href={
                    file.isAuthenticated && !file._id.startsWith("temp_")
                      ? `/api/asset?fileId=${file._id}`
                      : file.path
                  }
                  className="group flex gap-1"
                  download
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <DocumentArrowDownIcon
                    className="h-5 w-5 text-indigo-600 group-hover:text-indigo-900 group-hover:outline-none"
                    aria-hidden="true"
                  />
                  <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-900 group-hover:outline-none">
                    {file.originalname}
                  </span>
                </a>
              </div>
            </li>
          )
        )}
      </ul>
    );
  }
  return <>{fieldDataItem.value}</>;
};
