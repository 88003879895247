"use client";

import {
  EnvelopeIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon
} from "@heroicons/react/24/outline";
import { FooterAgreements } from "components/FooterAgreements";
import {
  generateGoogleMapsLink,
  getStatePostalCodeString
} from "helpers/address";
import { getDoesObjectHaveValue } from "helpers/utils";
import type { Client } from "types/model/client";
import { cn } from "utils/cn";

export const Footer = ({
  client,
  shouldAddTopBorder = false
}: {
  client: Client;
  shouldAddTopBorder: boolean;
}) => {
  return (
    <footer
      className={cn(
        "bg-white",
        shouldAddTopBorder && "border-t border-gray-200"
      )}
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:grid md:grid-cols-3 md:gap-8">
          <div>
            <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
              Contact
            </h3>
            <ul className="mt-4 space-y-4">
              {getDoesObjectHaveValue(client?.address) && (
                <li>
                  <a
                    href={generateGoogleMapsLink(client.address, client)}
                    className="flex text-gray-400 hover:text-gray-500"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span className="sr-only">Address</span>
                    <MapPinIcon className="h-6 w-6" />
                    <span className="ml-2">
                      {client.address.address1 && (
                        <span className="block">{client.address.address1}</span>
                      )}
                      {client.address.address2 && (
                        <span className="block">{client.address.address2}</span>
                      )}
                      {client.address.city && (
                        <span className="block">{client.address.city}</span>
                      )}
                      {(client.address.state || client.address.postalCode) && (
                        <span className="block">
                          {getStatePostalCodeString(client.address)}
                        </span>
                      )}
                    </span>
                  </a>
                </li>
              )}
              {client?.phoneNumber && (
                <li>
                  <a
                    href={`tel:${client?.phoneNumber}`}
                    className="flex text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Phone number</span>
                    <PhoneIcon className="h-6 w-6 flex-shrink-0" />
                    <span className="ml-2 truncate">{client?.phoneNumber}</span>
                  </a>
                </li>
              )}
              {client?.contactEmail && (
                <li>
                  <a
                    href={`mailto:${client?.contactEmail}`}
                    className="flex text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Email</span>
                    <EnvelopeIcon className="h-6 w-6 flex-shrink-0" />
                    <span className="ml-2 truncate">
                      {client?.contactEmail}
                    </span>
                  </a>
                </li>
              )}
              {client?.webAddress && (
                <li>
                  <a
                    href={client?.webAddress}
                    className="flex text-gray-400 hover:text-gray-500"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <span className="sr-only">Website address</span>
                    <GlobeAltIcon className="h-6 w-6 flex-shrink-0" />
                    <span className="ml-2 truncate">{client?.webAddress}</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
          {(client?.facebookHandle ||
            client?.instagramHandle ||
            client?.twitterHandle) && (
            <div className="mt-12 md:mt-0">
              <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">
                Follow us
              </h3>
              <ul className="mt-4 space-y-4">
                {client?.facebookHandle && (
                  <li>
                    <a
                      href={`https://www.facebook.com/${client?.facebookHandle}`}
                      className="flex text-gray-400 hover:text-gray-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        className="h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="ml-2">Facebook</span>
                    </a>
                  </li>
                )}

                {client?.instagramHandle && (
                  <li>
                    <a
                      href={`https://www.instagram.com/${client?.instagramHandle}`}
                      className="flex text-gray-400 hover:text-gray-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        className="h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="ml-2">Instagram</span>
                    </a>
                  </li>
                )}

                {client?.twitterHandle && (
                  <li>
                    <a
                      href={`https://twitter.com/${client?.twitterHandle}`}
                      className="flex text-gray-400 hover:text-gray-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        className="h-6 w-6 p-0.5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <g>
                          <polygon points="12.153992,10.729553 8.088684,5.041199 5.92041,5.041199 10.956299,12.087097 11.59021,12.97345    15.900635,19.009583 18.068909,19.009583 12.785217,11.615906  " />
                          <path d="M21.15979,1H2.84021C1.823853,1,1,1.823853,1,2.84021v18.31958C1,22.176147,1.823853,23,2.84021,23h18.31958   C22.176147,23,23,22.176147,23,21.15979V2.84021C23,1.823853,22.176147,1,21.15979,1z M15.235352,20l-4.362549-6.213013   L5.411438,20H4l6.246887-7.104675L4,4h4.764648l4.130127,5.881958L18.06958,4h1.411377l-5.95697,6.775635L20,20H15.235352z" />
                        </g>
                      </svg>
                      <span className="ml-2">X/Twitter</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
          <FooterAgreements />
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8 sm:flex sm:justify-between">
          <p className="text-gray-400">
            &copy; {new Date().getFullYear()} {client?.name}
          </p>
          <p className="text-gray-400">
            <span className="sr-only">Group Activity Booking System - </span>
            Powered by{" "}
            <a
              className="text-gray-400 hover:text-gray-500"
              href="https://www.pembee.app"
            >
              Pembee
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};
