import { FormRenderer } from "@data-driven-forms/react-form-renderer";
import {
  AttendeeModalFormTemplate,
  componentMapper,
  FormTemplate
} from "components/form/FormTemplate";
import { generateAttendeeFormSchema } from "helpers/form";
import useCreateCurrentUserAttendee from "hooks/useCreateCurrentUserAttendee";
import type { FormDataItem } from "types/form";
import type { Client } from "types/model/client";
import type { Field } from "types/model/field";

export const NewAttendeeForm = ({
  activityGroupId,
  attendeeFields,
  client,
  isInModal = false,
  lineItemId,
  handleSubmit,
  onCancel,
  onCreate
}: {
  activityGroupId?: string | string[];
  attendeeFields: Field[];
  client: Client;
  isInModal?: boolean;
  lineItemId?: string;
  handleSubmit?: (formData: FormDataItem[]) => void;
  onCancel: () => void;
  onCreate?: (lineItemId: string, attendeeId: string) => void;
}) => {
  const [createCurrentUserAttendee] = useCreateCurrentUserAttendee();

  const formSchema = generateAttendeeFormSchema({
    activityGroupId,
    assignToUser: false,
    attendeeData: [],
    client,
    fieldsData: attendeeFields,
    isAdminUse: false
  });

  const onSubmit = async (formData: FormDataItem[]): Promise<void> => {
    const response = await createCurrentUserAttendee(formData);
    const newAttendeeId = response?.data._id;

    if (newAttendeeId && lineItemId && onCreate) {
      onCreate(lineItemId, newAttendeeId);
    }
  };

  const handleOnCancel = () => {
    onCancel();
  };

  return (
    <FormRenderer
      componentMapper={componentMapper}
      FormTemplate={isInModal ? AttendeeModalFormTemplate : FormTemplate}
      schema={formSchema}
      onSubmit={handleSubmit || onSubmit}
      onCancel={handleOnCancel}
    />
  );
};
