import type {
  Field,
  FormOptions,
  FormRendererProps,
  Schema,
  Validator
} from "@data-driven-forms/react-form-renderer";
import componentTypes from "@data-driven-forms/react-form-renderer/component-types";
import dataTypes from "@data-driven-forms/react-form-renderer/data-types";
import validatorTypes from "@data-driven-forms/react-form-renderer/validator-types";
import type { TimeZone } from "@vvo/tzdb";
import { getTimeZones } from "@vvo/tzdb";
import type { AxiosError } from "axios";
import { getStatesList } from "helpers/address";
import { getCurrencyAmountForDisplay } from "helpers/currency";
import { formatDate, getTimeZoneDisplayFormat, padNumber } from "helpers/date";
import {
  filterableFieldTypes,
  getCanFieldBeMadeAdminOnly,
  getCanFieldBeUsedAsFilter,
  getCanFieldBeUsedAsHomePageFilter,
  getCanFieldLabelBeChanged,
  getIsFieldMakeAdminOnlyDisallowed,
  getIsFieldMakeNotRequiredDisallowed,
  getIsFieldStatusUpdateDisallowed,
  homePageFilterableFieldTypes
} from "helpers/field";
import {
  getDdfDataType,
  getFormComponentFromFieldType,
  getInputType,
  isCcvPaymentMethod,
  isStripePaymentMethod,
  isTaxFreeChildcarePaymentMethod
} from "helpers/helpers";
import { getIsBritishSpelling } from "helpers/locale";
import { getIsClientPremiumPlanOrHigher } from "helpers/plan-level";
import { getPlainTextFromRaw } from "helpers/string";
import { getCanSubscriptionPlanBeAddedToNewTicket } from "helpers/subscription-plan";
import { FieldUsage } from "types/constants";
import { FormTemplateType } from "types/form";
import type { IdsHash } from "types/general";
import { TicketType, type AddOn, type Ticket } from "types/model/activity";
import type {
  ActivityGroup,
  ActivityGroupFullListItem
} from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { Agreement } from "types/model/booking-agreement";
import { AgreementUsage } from "types/model/booking-agreement";
import type { Client } from "types/model/client";
import type { Email } from "types/model/email";
import type { Field as FieldModel } from "types/model/field";
import { FieldType, TextAreaFormatting, UploadType } from "types/model/field";
import type { FieldData } from "types/model/field-data";
import type { FieldOption } from "types/model/field-option";
import type { FormErrors, FormValues } from "types/model/form";
import type { HomeTab } from "types/model/home-tab";
import type {
  CcvProvider,
  PaymentMethodInstance,
  StripeConnectedAccountWithStripeAccount
} from "types/model/payment";
import type { SubscriptionPlan } from "types/model/subscription-plan";
import type { CurrentUser, User, UserReduced } from "types/model/user";
import type { Venue } from "types/model/venue";
import { convertToHours } from "utils/convertToHours";
import { convertToMinutes } from "utils/convertToMinutes";
import { padWithZero } from "utils/padWithZero";
import { timeToMinutes } from "utils/timeToMinutes";

interface GenerateActivityTicketFormSchemaData {
  client: Client;
  subscriptionPlansData: SubscriptionPlan[];
  allTicketsData: Ticket<SubscriptionPlan>[];
  formTemplate?: FormTemplateType;
  prefix: string;
  editingTicket?: Ticket;
}

interface GenerateActivityAddOnFormSchemaData {
  formTemplate?: FormTemplateType;
  prefix: string;
  editingAddOn?: AddOn;
  client: Client;
}

interface GenerateVenueFormSchemaParams {
  venueData?: Venue;
  formTemplate?: FormTemplateType;
  prefix?: string;
  client: Client;
}

export const generateVenueFormSchema = ({
  venueData,
  formTemplate,
  prefix = "",
  client
}: GenerateVenueFormSchemaParams): Schema => {
  const schema = {
    fields: [
      {
        index: 0,
        arrayField: false,
        component: getFormComponentFromFieldType(FieldType.Text),
        name: `${prefix ? `${prefix}.` : ""}name`,
        label: "Name",
        helpText: null,
        isRequired: true,
        dataType: getDdfDataType(FieldType.Text),
        validate: [
          {
            type: "required"
          }
        ],
        initialValue: venueData?.name,
        formTemplate: formTemplate || FormTemplateType.Default
      },
      {
        index: 1,
        arrayField: false,
        component: getFormComponentFromFieldType(FieldType.Text),
        name: `${prefix ? `${prefix}.` : ""}address1`,
        label: "Address line 1",
        dataType: getDdfDataType(FieldType.Text),
        initialValue: venueData?.address?.address1,
        formTemplate: formTemplate || FormTemplateType.Default
      },
      {
        index: 2,
        arrayField: false,
        component: getFormComponentFromFieldType(FieldType.Text),
        name: `${prefix ? `${prefix}.` : ""}address2`,
        label: "Address line 2",
        dataType: getDdfDataType(FieldType.Text),
        initialValue: venueData?.address?.address2,
        formTemplate: formTemplate || FormTemplateType.Default
      },
      {
        index: 3,
        arrayField: false,
        component: getFormComponentFromFieldType(FieldType.Text),
        name: `${prefix ? `${prefix}.` : ""}city`,
        label: "City",
        dataType: getDdfDataType(FieldType.Text),
        initialValue: venueData?.address?.city,
        formTemplate: formTemplate || FormTemplateType.Default
      },
      ...(["US", "AU"].includes(client.country)
        ? [
            {
              index: 4,
              arrayField: false,
              component: getFormComponentFromFieldType(FieldType.SelectList),
              name: `${prefix ? `${prefix}.` : ""}state`,
              label: "State",
              dataType: getDdfDataType(FieldType.SelectList),
              options: getStatesList(client.country),
              initialValue: venueData?.address?.state,
              formTemplate: formTemplate || FormTemplateType.Default
            }
          ]
        : []),
      ...(client.country === "US"
        ? [
            {
              index: 5,
              arrayField: false,
              component: getFormComponentFromFieldType(FieldType.Text),
              name: `${prefix ? `${prefix}.` : ""}postalCode`,
              label: "ZIP Code",
              dataType: getDdfDataType(FieldType.Text),
              initialValue: venueData?.address?.postalCode,
              validate: [
                {
                  type: "pattern",
                  pattern: /^\d{5}(-\d{4})?$/,
                  message: "Please enter a valid ZIP code"
                }
              ],
              formTemplate: formTemplate || FormTemplateType.Default
            }
          ]
        : [
            {
              index: 5,
              arrayField: false,
              component: getFormComponentFromFieldType(FieldType.Text),
              name: `${prefix ? `${prefix}.` : ""}postalCode`,
              label: "Postal code",
              dataType: getDdfDataType(FieldType.Text),
              initialValue: venueData?.address?.postalCode,
              formTemplate: formTemplate || FormTemplateType.Default,
              ...(client.country === "AU" && {
                validate: [
                  {
                    type: "pattern",
                    pattern: /^\d{4}$/,
                    message: "Please enter a valid Postal code"
                  }
                ]
              })
            }
          ])
    ]
  };
  return schema;
};

interface GetConfiguredFieldsData {
  fieldsData: FieldModel[];
  initialData: FieldData[];
  offset?: number;
  arrayField?: boolean;
  venuesData?: Venue[];
  isAdminUse: boolean;
  client: Client;
  activityGroup?: ActivityGroup;
  attendee?: Attendee;
}

// TODO: Move setting of initial values to a separate function and passed in as `initialValues` prop to `FormRenderer`
export const getConfiguredFields = ({
  fieldsData,
  initialData,
  offset = 0,
  arrayField = false,
  venuesData = [],
  isAdminUse,
  client,
  activityGroup,
  attendee
}: GetConfiguredFieldsData): Field[] => {
  const fields = fieldsData
    .filter(
      fieldItem => fieldItem.enabled && (isAdminUse || !fieldItem.adminOnly)
    )
    .map((fieldItem, index) => {
      if (
        fieldItem.type === FieldType.Text ||
        fieldItem.type === FieldType.Textarea ||
        fieldItem.type === FieldType.Email ||
        fieldItem.type === FieldType.Currency ||
        fieldItem.type === FieldType.Integer ||
        fieldItem.type === FieldType.Number ||
        fieldItem.type === FieldType.Date
      ) {
        let initialValue = initialData?.find(
          fieldDataItem => fieldDataItem?.field?._id === fieldItem._id
        )?.value;

        if (initialValue && fieldItem.type === FieldType.Date) {
          initialValue = formatDate(
            initialValue,
            "yyyy-MM-dd",
            client.timeZone
          );
        } else if (initialValue && fieldItem.type === FieldType.Currency) {
          initialValue = getCurrencyAmountForDisplay(
            initialValue,
            client.currency
          );
        }

        return {
          index: index + offset,
          arrayField,
          component: getFormComponentFromFieldType(fieldItem.type),
          name: fieldItem._id,
          label: fieldItem.title,
          helpText: fieldItem.helpText,
          isRequired: fieldItem.required,
          isAdminOnly: fieldItem.adminOnly,
          dataType: getDdfDataType(fieldItem.type),
          ...((fieldItem.type === FieldType.Email ||
            fieldItem.type === FieldType.Integer ||
            fieldItem.type === FieldType.Number) && {
            inputType: getInputType(fieldItem.type)
          }),
          ...(fieldItem.type === FieldType.Textarea && {
            shouldIncludeLinkOptions: fieldItem.usage === FieldUsage.Activity,
            shouldIncludeMediaOptions: fieldItem.usage === FieldUsage.Activity,
            shouldIncludeHeadingsOptions:
              fieldItem.usage === FieldUsage.Activity,
            height: fieldItem.usage === FieldUsage.Activity ? 500 : 200,
            shouldUseRichText:
              fieldItem.textareaFormatting === TextAreaFormatting.RichText
          }),
          validate: [
            ...(fieldItem.required
              ? [
                  {
                    type: validatorTypes.REQUIRED
                  },
                  (value: string) => {
                    if (fieldItem.type === FieldType.Textarea) {
                      const valueAsPlainText = getPlainTextFromRaw(value);

                      return valueAsPlainText === "" ? "Required" : undefined;
                    }

                    return undefined;
                  }
                ]
              : []),
            ...(fieldItem.type === FieldType.Email
              ? [
                  {
                    type: validatorTypes.PATTERN,
                    pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                    message: "Not a valid email address"
                  }
                ]
              : [])
          ],
          ...(initialValue && { initialValue }),
          ...(!!activityGroup &&
            !!attendee &&
            fieldItem.internalKey === "attendee_dob" && {
              activityGroup,
              attendee
            })
        };
      } else if (fieldItem.type === FieldType.SelectList) {
        const initialValue = initialData?.find(
          fieldDataItem => fieldDataItem?.field?._id === fieldItem._id
        )?.valueRef?._id;

        return {
          index: index + offset,
          arrayField,
          component: getFormComponentFromFieldType(fieldItem.type),
          name: fieldItem._id,
          label: fieldItem.title,
          helpText: fieldItem.helpText,
          options: fieldItem.fieldOptions?.map(({ _id, name }) => ({
            value: _id,
            label: name
          })),
          isRequired: fieldItem.required,
          isAdminOnly: fieldItem.adminOnly,
          dataType: getDdfDataType(fieldItem.type),
          ...(fieldItem.required && {
            validate: [
              {
                type: validatorTypes.REQUIRED
              }
            ]
          }),
          initialValue: initialValue
        };
      } else if (fieldItem.type === FieldType.CheckboxMultiple) {
        const initialValue = initialData
          ?.find(fieldDataItem => fieldDataItem?.field?._id === fieldItem._id)
          ?.valueRefArray?.map(item => item._id);

        return {
          index: index + offset,
          arrayField,
          component: getFormComponentFromFieldType(fieldItem.type),
          name: fieldItem._id,
          label: fieldItem.title,
          helpText: fieldItem.helpText,
          options: fieldItem.fieldOptions?.map(({ _id, name }) => ({
            value: _id,
            label: name
          })),
          isRequired: fieldItem.required,
          isAdminOnly: fieldItem.adminOnly,
          dataType: getDdfDataType(fieldItem.type),
          ...(fieldItem.required && {
            validate: [
              {
                type: validatorTypes.REQUIRED
              }
            ]
          }),
          initialValue
        };
      } else if (fieldItem.type === FieldType.CheckboxSingle) {
        const initialValue = initialData?.find(
          fieldDataItem => fieldDataItem?.field?._id === fieldItem._id
        )?.value;

        return {
          index: index + offset,
          arrayField,
          component: getFormComponentFromFieldType(fieldItem.type),
          name: fieldItem._id,
          label: fieldItem.title,
          helpText: fieldItem.helpText,
          isRequired: fieldItem.required,
          isAdminOnly: fieldItem.adminOnly,
          dataType: getDdfDataType(fieldItem.type),
          ...(fieldItem.required && {
            validate: [
              {
                type: validatorTypes.REQUIRED
              }
            ]
          }),
          initialValue: initialValue || false
        };
      } else if (fieldItem.type === FieldType.Venue) {
        const initialValue = initialData?.find(
          fieldDataItem => fieldDataItem?.field?._id === fieldItem._id
        )?.valueRefVenue?._id;

        return {
          index: index + offset,
          arrayField,
          component: getFormComponentFromFieldType(fieldItem.type),
          name: fieldItem._id,
          label: fieldItem.title,
          helpText: fieldItem.helpText,
          options: venuesData.map(({ _id, name }) => ({
            value: _id,
            label: name
          })),
          isRequired: fieldItem.required,
          dataType: getDdfDataType(fieldItem.type),
          client,
          ...(fieldItem.required && {
            validate: [
              {
                type: validatorTypes.REQUIRED
              }
            ]
          }),
          ...(initialValue && { initialValue })
        };
      } else if (fieldItem.type === FieldType.FileUpload) {
        const initialValue = initialData?.find(
          fieldDataItem => fieldDataItem?.field?._id === fieldItem._id
        )?.value;

        return {
          index: index + offset,
          arrayField,
          component: "file-upload",
          name: fieldItem._id,
          formFieldName: "fileUpload",
          label: fieldItem.title,
          helpText: fieldItem.helpText,
          isRequired: fieldItem.required,
          isAdminOnly: fieldItem.adminOnly,
          maxFiles: fieldItem.maxFiles,
          uploadType: fieldItem.uploadType,
          client,
          ...(fieldItem.required && {
            validate: [
              {
                type: validatorTypes.REQUIRED
              }
            ]
          }),
          ...(initialValue && { initialValue })
        };
      }
    });

  return fields;
};

interface GenerateUserFormSchemaData {
  fieldsData: FieldModel[];
  user?: User;
  formTemplate?: FormTemplateType;
  client: Client;
}

export const generateUserFormSchema = ({
  fieldsData,
  user,
  formTemplate = FormTemplateType.Default,
  client
}: GenerateUserFormSchemaData): Schema => {
  const configuredFields = getConfiguredFields({
    fieldsData,
    initialData: user ? user.fieldData : [],
    isAdminUse: true,
    client
  }).map(field => ({ ...field, formTemplate }));

  const schema = {
    fields: [
      ...configuredFields,
      {
        index: 14,
        arrayField: false,
        component: componentTypes.SWITCH,
        name: "enabled",
        label: "Enabled",
        dataType: dataTypes.BOOLEAN,
        formTemplate,
        initialValue: user ? user.enabled : true
      }
    ]
  };

  return schema;
};

interface GenerateUserRegisterFormSchemaData {
  fieldsData: FieldModel[];
  formTemplate?: FormTemplateType;
  userRegistrationAgreements: Agreement[];
  client: Client;
}

export const generateUserRegisterFormSchema = ({
  fieldsData,
  formTemplate = FormTemplateType.Default,
  userRegistrationAgreements,
  client
}: GenerateUserRegisterFormSchemaData): Schema => {
  const configuredFields = getConfiguredFields({
    fieldsData,
    initialData: [],
    isAdminUse: false,
    client
  }).map(field => ({ ...field, formTemplate }));

  const schema = {
    fields: [
      ...configuredFields,
      {
        index: configuredFields.length + 1,
        arrayField: false,
        component: componentTypes.TEXT_FIELD,
        name: "password",
        label: "Password",
        isRequired: true,
        inputType: "password",
        validate: [
          { type: "required" },
          {
            type: validatorTypes.MIN_LENGTH,
            threshold: 8
          }
        ],
        formTemplate
      },
      {
        index: configuredFields.length + 2,
        arrayField: false,
        component: componentTypes.TEXT_FIELD,
        name: "confirmPassword",
        label: "Confirm password",
        isRequired: true,
        inputType: "password",
        validate: [{ type: "required" }],
        formTemplate
      },
      ...userRegistrationAgreements.map(agreement => ({
        index: configuredFields.length + 3,
        arrayField: false,
        component: "agreement",
        name: `agreement-${agreement._id}`,
        label: agreement.name,
        agreement: agreement,
        isRequired: true,
        validate: [{ type: "required" }],
        formTemplate
      }))
    ]
  };

  return schema;
};

interface GenerateCurrentUserFormSchemaData {
  fieldsData: FieldModel[];
  user: CurrentUser;
  formTemplate?: FormTemplateType;
  client: Client;
  fieldIds?: string[];
}

export const generateCurrentUserFormSchema = ({
  fieldsData,
  user,
  formTemplate = FormTemplateType.Default,
  client,
  fieldIds
}: GenerateCurrentUserFormSchemaData): Schema => {
  let configuredFields = getConfiguredFields({
    fieldsData,
    initialData: user ? user.fieldData : [],
    isAdminUse: false,
    client
  }).map(field => ({ ...field, formTemplate }));

  if (fieldIds) {
    configuredFields = configuredFields
      .filter(field => fieldIds.includes(field.name))
      .map((field, index) => ({ ...field, index }));
  }

  const schema = {
    fields: configuredFields
  };

  return schema;
};

interface GenerateAttendeeFormSchemaData {
  assignToUser: boolean;
  attendeeData: FieldData[];
  client: Client;
  fieldsData: FieldModel[];
  isAdminUse: boolean;
  activityGroupId?: string | string[];
  user?: string;
  usersFullListData?: UserReduced[];
  attendee?: Attendee;
  activityGroup?: ActivityGroup;
}

export const generateAttendeeFormSchema = ({
  assignToUser,
  attendeeData,
  client,
  fieldsData,
  isAdminUse,
  activityGroupId,
  user,
  usersFullListData = [],
  attendee,
  activityGroup
}: GenerateAttendeeFormSchemaData): Schema => {
  const applicableAttendeeFields = fieldsData.filter(field => {
    // Doing this for the time being as a lot of existing fields don't have this value set
    const appliesToAllActivityGroups =
      field.appliesToAllActivityGroups !== false;

    const fieldDataExistsAndNotSpecificForActivityGroup =
      !activityGroupId &&
      attendeeData.find(
        fieldDataItem => fieldDataItem?.field?._id === field._id
      );

    const activityGroupIds = activityGroupId
      ? Array.isArray(activityGroupId)
        ? activityGroupId
        : [activityGroupId]
      : [];

    // If the field is applies to a provided activity groups, included it
    const isInActivityGroup = field.activityGroups?.some(activityGroup =>
      activityGroupIds.includes(activityGroup)
    );

    return (
      appliesToAllActivityGroups ||
      fieldDataExistsAndNotSpecificForActivityGroup ||
      isInActivityGroup
    );
  });

  const offset = assignToUser ? 1 : 0;

  const configuredFields = getConfiguredFields({
    fieldsData: applicableAttendeeFields,
    initialData: attendeeData,
    isAdminUse,
    offset,
    client,
    activityGroup,
    attendee
  });

  const schema = {
    fields: [
      ...(assignToUser
        ? [
            {
              index: 0,
              component: "autocomplete",
              name: "userId",
              label: "User",
              isRequired: true,
              dataType: dataTypes.STRING,
              options: usersFullListData,
              validate: [
                {
                  type: "required"
                }
              ],
              primaryField: "fullName",
              secondaryField: "email",
              shouldAddDisabledIndicator: true,
              defaultValue: user
            }
          ]
        : []),
      ...configuredFields
    ]
  };

  return schema;
};

interface GenerateFieldFormSchemaData {
  fieldData: FieldModel;
  fieldOptionsUsedCheck: IdsHash;
  homeTabs: HomeTab<FieldModel, FieldOption, Venue>[];
  activityGroups: ActivityGroupFullListItem[];
  venues: Venue[];
  activityFields: FieldModel[];
  client: Client;
}

export const generateFieldFormSchema = ({
  fieldData,
  fieldOptionsUsedCheck,
  homeTabs,
  activityGroups,
  venues,
  activityFields,
  client
}: GenerateFieldFormSchemaData): Schema => {
  const isUsedForHomeTab = homeTabs.some(
    homeTab => homeTab.field._id === fieldData._id
  );

  const fieldOptionIdsUsedForHomeTab = homeTabs.reduce((acc, homeTab) => {
    const fieldOptionIds = homeTab.fieldOptions?.map(
      fieldOption => fieldOption._id
    ) as string[];

    acc = [...acc, ...fieldOptionIds];

    return acc;
  }, [] as string[]);

  // Doing this for the time being as a lot of existing fields don't have this value set
  const appliesToAllActivityGroups =
    fieldData.appliesToAllActivityGroups !== false;

  const canFieldLabelBeChanged = getCanFieldLabelBeChanged(
    fieldData.internalKey,
    fieldData.title
  );
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "title",
      label: "Label",
      helpText: canFieldLabelBeChanged
        ? "The label for this field cannot be changed. Consider using the help text if you wish to provide more information to your users."
        : "",
      isRequired: true,
      dataType: getDdfDataType(FieldType.Text),
      useWarnings: true,
      isDisabled: canFieldLabelBeChanged,
      validate: [
        {
          type: "required"
        },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 32,
          message:
            "We suggest keeping the label brief to ensure the best user experience. Consider using the help text below.",
          warning: true
        }
      ],
      initialValue: fieldData.title
    },
    ...(fieldData.usage === FieldUsage.Attendee &&
    !["attendee_firstName", "attendee_lastName"].includes(fieldData.internalKey)
      ? [
          {
            index: 1,
            arrayField: false,
            component: "radio-group-with-description-plain",
            name: "activityApplicability",
            label: "Activity applicability",
            isRequired: true,
            validate: [{ type: "required" }],
            options: [
              {
                value: "allActivityGroups",
                label: "All activities",
                description:
                  "If selected, the field will be displayed when booking any activity."
              },
              {
                value: "selectedActivityGroups",
                label: "Selected activities",
                description:
                  "If selected, the field will be displayed only when booking specific activities."
              }
            ],
            initialValue: appliesToAllActivityGroups
              ? "allActivityGroups"
              : "selectedActivityGroups"
          },
          {
            index: 1,
            arrayField: false,
            component: "activity-groups",
            name: "activityGroups",
            label: "Activities to apply to",
            activityGroups,
            venues,
            activityFields,
            client,
            helpText:
              "Field can also be applied to activities when they are created or edited.",
            initialValue: fieldData.activityGroups || [],
            condition: {
              when: "activityApplicability",
              is: "selectedActivityGroups",
              then: { visible: true }
            }
          }
        ]
      : []),
    ...(fieldData.type === FieldType.Textarea
      ? [
          {
            index: 1,
            arrayField: false,
            component: "radio-group-with-description-plain",
            name: "textareaFormatting",
            label: "Formatting",
            isRequired: true,
            validate: [{ type: "required" }],
            options: [
              {
                value: TextAreaFormatting.PlainText,
                label: "Plain text",
                description:
                  "Displays a simple multi-line plain text input. Recommended for User and Attendee fields."
              },
              {
                value: TextAreaFormatting.RichText,
                label: "Rich text",
                description: "Enables styling such as bold, italic etc."
              }
            ],
            initialValue: fieldData.textareaFormatting
          }
        ]
      : []),
    {
      index: 1,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Textarea),
      name: "helpText",
      label: "Help text",
      helpText: "Appears as additional information to the user.",
      isRequired: false,
      dataType: getDdfDataType(FieldType.Textarea),
      initialValue: fieldData.helpText,
      shouldIncludeLinkOptions: true,
      shouldIncludeMediaOptions: false
    },
    ...(fieldData.type === FieldType.FileUpload
      ? [
          {
            index: 2,
            arrayField: false,
            component: getFormComponentFromFieldType(FieldType.SelectList),
            name: "maxFiles",
            label: "Max files",
            helpText: "The maximum number of files that can be uploaded.",
            isRequired: true,
            dataType: getDdfDataType(FieldType.SelectList),
            validate: [
              {
                type: "required"
              }
            ],
            options: [
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              }
            ],
            initialValue: fieldData.maxFiles
              ? fieldData.maxFiles.toString()
              : undefined
          },
          {
            index: 2,
            arrayField: false,
            component: "radio-group-with-description-plain",
            name: "uploadType",
            label: "Upload type",
            isRequired: true,
            isDisabled: true,
            validate: [
              {
                type: "required"
              }
            ],
            options: [
              {
                value: UploadType.Image,
                label: "Image",
                description: "Allows PNG, JPG or GIF files to be uploaded."
              },
              {
                value: UploadType.Document,
                label: "Document",
                description:
                  "Allows PDF, DOCX, XLSX, PPTX, TXT or CSV files to be uploaded."
              }
            ],
            initialValue: fieldData.uploadType
          }
        ]
      : []),
    ...(getCanFieldBeUsedAsHomePageFilter(fieldData.type) &&
    fieldData.usage === FieldUsage.Activity
      ? [
          {
            index: 2,
            arrayField: false,
            component: componentTypes.CHECKBOX,
            name: "useForSiteFiltering",
            label: "Use for site filtering",
            helpText:
              "Controls whether the field appears as a filter option on the site's homepage.",
            isRequired: false,
            dataType: dataTypes.BOOLEAN,
            initialValue: fieldData.useForSiteFiltering
          }
        ]
      : []),
    ...(getCanFieldBeUsedAsFilter(fieldData.type)
      ? [
          {
            index: 3,
            arrayField: false,
            component: componentTypes.CHECKBOX,
            name: "useForAdminFiltering",
            label: "Use for admin filtering",
            helpText:
              "Controls whether the field appears as a filter option within the admin area.",
            isRequired: false,
            dataType: dataTypes.BOOLEAN,
            initialValue: fieldData.useForAdminFiltering
          }
        ]
      : []),
    ...(getCanFieldBeMadeAdminOnly(fieldData.usage) &&
    !getIsFieldMakeAdminOnlyDisallowed(fieldData.internalKey)
      ? [
          {
            index: 4,
            arrayField: false,
            component: componentTypes.CHECKBOX,
            name: "adminOnly",
            label: "Admin only",
            helpText: "Makes the field visible only to admin users.",
            isRequired: false,
            dataType: dataTypes.BOOLEAN,
            initialValue: fieldData.adminOnly
          }
        ]
      : []),
    {
      index: 4,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "required",
      label: "Required",
      helpText: null,
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      initialValue: fieldData.required,
      isDisabled: getIsFieldMakeNotRequiredDisallowed(fieldData.internalKey)
    },
    {
      index: 5,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "enabled",
      label: "Enabled",
      helpText: isUsedForHomeTab
        ? "This field cannot be disabled as it is used for a Home page tab."
        : null,
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      initialValue: fieldData.enabled,
      isDisabled:
        getIsFieldStatusUpdateDisallowed(fieldData.internalKey) ||
        isUsedForHomeTab
    },
    ...(fieldData.type === FieldType.SelectList ||
    fieldData.type === FieldType.CheckboxMultiple
      ? [
          {
            index: 6,
            component: "section-header",
            name: "fieldOptionsHeader",
            title: "Field options"
          },
          {
            index: 7,
            component: "field-options",
            name: "fieldOptions",
            label: "Field options",
            required: true,
            validate: [
              {
                type: "required",
                message: "Please add at least one field option."
              }
            ],
            arrayField: true,
            initialValue: fieldData.fieldOptions?.map(fieldOption => ({
              _id: fieldOption._id,
              field: fieldOption.field,
              name: fieldOption.name,
              enabled: fieldOption.enabled
            })),
            fieldOptionsUsedCheck,
            fieldOptionIdsUsedForHomeTab,
            isNewField: false
          }
        ]
      : [])
  ];

  const schema = {
    fields: [...fields]
  };

  return schema;
};

interface GenerateNewFieldFormSchemaData {
  fieldUsageInitialValue: FieldUsage;
  activityGroups: ActivityGroupFullListItem[];
  venues: Venue[];
  activityFields: FieldModel[];
  client: Client;
}

export const generateNewFieldFormSchema = ({
  fieldUsageInitialValue,
  activityGroups,
  venues,
  activityFields,
  client
}: GenerateNewFieldFormSchemaData): Schema => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.SelectList),
      name: "usage",
      label: "Field usage",
      helpText: null,
      isRequired: true,
      dataType: getDdfDataType(FieldType.SelectList),
      validate: [
        {
          type: "required"
        }
      ],
      options: [
        {
          value: FieldUsage.User,
          label: "User"
        },
        {
          value: FieldUsage.Attendee,
          label: "Attendee"
        },
        {
          value: FieldUsage.Activity,
          label: "Activity"
        }
      ],
      initialValue: fieldUsageInitialValue || FieldUsage.User
    },
    {
      index: 1,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "title",
      label: "Label",
      helpText: null,
      isRequired: true,
      dataType: getDdfDataType(FieldType.Text),
      useWarnings: true,
      validate: [
        {
          type: "required"
        },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 32,
          message:
            "We suggest keeping the label brief to ensure the best user experience. Consider using the help text below.",
          warning: true
        }
      ]
    },
    {
      index: 1,
      arrayField: false,
      component: "select-field-type",
      name: "type",
      label: "Field type",
      helpText: null,
      isRequired: true,
      dataType: getDdfDataType(FieldType.SelectList),
      initialValue: FieldType.Text,
      validate: [{ type: "required" }],
      emptyOptionLabel: "Select a field type",
      resolveProps: (
        _props: FormRendererProps,
        _field: string,
        formOptions: FormOptions
      ) => {
        const values = formOptions.getState().values;

        return {
          showFile: [FieldUsage.User, FieldUsage.Attendee].includes(
            values?.usage
          )
        };
      }
    },
    {
      index: 3,
      arrayField: false,
      component: "radio-group-with-description-plain",
      name: "textareaFormatting",
      label: "Formatting",
      isRequired: true,
      validate: [{ type: "required" }],
      options: [
        {
          value: TextAreaFormatting.PlainText,
          label: "Plain text",
          description:
            "Displays a simple multi-line plain text input. Recommended for User and Attendee fields."
        },
        {
          value: TextAreaFormatting.RichText,
          label: "Rich text",
          description: "Enables styling such as bold, italic etc."
        }
      ],
      condition: {
        when: "type",
        is: FieldType.Textarea,
        then: { visible: true }
      }
    },
    {
      index: 2,
      arrayField: false,
      component: "radio-group-with-description-plain",
      name: "activityApplicability",
      label: "Activity applicability",
      isRequired: true,
      validate: [{ type: "required" }],
      options: [
        {
          value: "allActivityGroups",
          label: "All activities",
          description:
            "If selected, the field will be displayed when booking any activity."
        },
        {
          value: "selectedActivityGroups",
          label: "Selected activities",
          description:
            "If selected, the field will be displayed only when booking specific activities."
        }
      ],
      condition: {
        when: "usage",
        is: FieldUsage.Attendee,
        then: { visible: true }
      }
    },
    {
      index: 2,
      arrayField: false,
      component: "activity-groups",
      name: "activityGroups",
      label: "Activities to apply to",
      activityGroups,
      venues,
      activityFields,
      client,
      helpText:
        "Field can also be applied to activities when they are created or edited.",
      condition: {
        when: "activityApplicability",
        is: "selectedActivityGroups",
        then: { visible: true }
      }
    },
    {
      index: 4,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Textarea),
      name: "helpText",
      label: "Help text",
      helpText: "Appears as additional information to the user.",
      isRequired: false,
      shouldIncludeLinkOptions: true,
      shouldIncludeMediaOptions: false
    },
    {
      index: 4,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.SelectList),
      name: "maxFiles",
      label: "Max files",
      helpText: "The maximum number of files that can be uploaded.",
      isRequired: true,
      dataType: getDdfDataType(FieldType.SelectList),
      validate: [
        {
          type: "required"
        }
      ],
      options: [
        {
          value: 1,
          label: "1"
        },
        {
          value: 2,
          label: "2"
        },
        {
          value: 3,
          label: "3"
        },
        {
          value: 4,
          label: "4"
        },
        {
          value: 5,
          label: "5"
        }
      ],
      condition: {
        when: "type",
        is: FieldType.FileUpload,
        then: { visible: true }
      }
    },
    {
      index: 5,
      arrayField: false,
      component: "radio-group-with-description-plain",
      name: "uploadType",
      label: "Upload type",
      isRequired: true,
      validate: [
        {
          type: "required"
        }
      ],
      options: [
        {
          value: UploadType.Image,
          label: "Image",
          description: "Allows PNG, JPG or GIF files to be uploaded."
        },
        {
          value: UploadType.Document,
          label: "Document",
          description:
            "Allows PDF, DOCX, XLSX, PPTX, TXT or CSV files to be uploaded."
        }
      ],
      condition: {
        when: "type",
        is: FieldType.FileUpload,
        then: { visible: true }
      }
    },
    {
      index: 6,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "useForSiteFiltering",
      label: "Use for site filtering",
      helpText:
        "Controls whether the field appears as a filter option on the site's homepage.",
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      condition: {
        and: [
          {
            when: "usage",
            is: FieldUsage.Activity
          },
          {
            when: "type",
            is: homePageFilterableFieldTypes
          }
        ],
        then: { visible: true }
      }
    },
    {
      index: 7,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "useForAdminFiltering",
      label: "Use for admin filtering",
      helpText:
        "Controls whether the field appears as a filter option within the admin area.",
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      condition: {
        when: "type",
        is: filterableFieldTypes,
        then: { visible: true }
      }
    },
    {
      index: 8,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "adminOnly",
      label: "Admin only",
      helpText: "Makes the field visible only to admin users.",
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      condition: {
        or: [
          {
            when: "usage",
            is: FieldUsage.User
          },
          {
            when: "usage",
            is: FieldUsage.Attendee
          }
        ],
        then: { visible: true }
      }
    },
    {
      index: 9,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "required",
      label: "Required",
      helpText: null,
      isRequired: false,
      dataType: dataTypes.BOOLEAN
    },
    {
      index: 10,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "enabled",
      label: "Enabled",
      helpText: null,
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      initialValue: true
    },
    {
      index: 11,
      component: "section-header",
      name: "fieldOptionsHeader",
      title: "Field options",
      condition: {
        or: [
          { when: "type", is: FieldType.SelectList },
          {
            when: "type",
            is: FieldType.CheckboxMultiple
          }
        ],
        then: { visible: true }
      }
    },
    {
      index: 12,
      component: "field-options",
      name: "fieldOptions",
      label: "Field options",
      condition: {
        or: [
          { when: "type", is: FieldType.SelectList },
          {
            when: "type",
            is: FieldType.CheckboxMultiple
          }
        ],
        then: { visible: true }
      },
      required: true,
      validate: [
        {
          type: "required",
          message: "Please add at least one field option"
        }
      ],
      arrayField: true,
      initialValue: [],
      fieldOptionsUsedCheck: {},
      isNewField: true
    }
  ];

  const schema = {
    fields: [...fields]
  };

  return schema as Schema;
};

export const generateClientSettingsFormSchema = (
  clientData: Client
): Schema => {
  const timeZones = getTimeZones();

  const isBritishSpelling = getIsBritishSpelling(clientData);

  const fields = [
    {
      index: 0,
      component: "section-header",
      name: "clientNameAndLogo",
      title: isBritishSpelling
        ? "Organisation identity"
        : "Organization identity"
    },
    {
      index: 1,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "name",
      label: isBritishSpelling ? "Organisation name" : "Organization name",
      helpText: null,
      isRequired: true,
      dataType: getDdfDataType(FieldType.Text),
      validate: [
        {
          type: "required"
        }
      ],
      initialValue: clientData.name
    },
    {
      index: 2,
      component: "image",
      name: "clientLogo",
      label: "Logo",
      initialValue: clientData.clientLogo,
      helpText:
        "If you need help uploading an appropriate logo please contact us."
    },
    {
      index: 3,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.SelectList),
      name: "headerDisplay",
      label: "Header display",
      isRequired: true,
      options: [
        {
          value: "nameAndLogo",
          label: isBritishSpelling
            ? "Logo and organisation name"
            : "Logo and organization name"
        },
        {
          value: "nameOnly",
          label: isBritishSpelling
            ? "Organisation name only"
            : "Organization name only"
        },
        {
          value: "logoOnly",
          label: "Logo only"
        }
      ],
      dataType: getDdfDataType(FieldType.SelectList),
      validate: [
        {
          type: "required"
        }
      ],
      helpText: "Controls the display in the site header",
      initialValue: clientData.headerDisplay
    },
    ...(clientData.country === "US"
      ? [
          {
            index: 4,
            component: "section-header",
            name: "officialDetails",
            title: "Official details"
          },
          {
            index: 5,
            arrayField: false,
            component: getFormComponentFromFieldType(FieldType.Text),
            name: "taxIdentificationNumber",
            label: "Tax Identification Number",
            helpText:
              "Often referred to as an EIN or Business Tax ID, this number will be included in emails sent to your users.",
            dataType: getDdfDataType(FieldType.Text),
            initialValue: clientData.taxIdentificationNumber
          }
        ]
      : []),
    {
      index: 6,
      component: "section-header",
      name: "clientContactAndSocial",
      title: "Contact & social",
      description:
        "Links will be included in your site footer and emails sent to your users."
    },
    {
      index: 7,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "contactEmail",
      label: "Contact email",
      helpText:
        "Used as the 'reply to' address for any emails sent to your users.",
      isRequired: true,
      dataType: getDdfDataType(FieldType.Text),
      validate: [
        {
          type: "required"
        },
        {
          type: validatorTypes.PATTERN,
          pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
          message: "Please enter a valid email address"
        }
      ],
      initialValue: clientData.contactEmail
    },
    {
      index: 8,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "phoneNumber",
      label: "Phone number",
      dataType: getDdfDataType(FieldType.Text),
      initialValue: clientData.phoneNumber
    },
    {
      index: 8,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "webAddress",
      label: "Website address",
      dataType: getDdfDataType(FieldType.Text),
      initialValue: clientData.webAddress,
      helpText: "The URL of your own website.",
      validate: [
        {
          type: validatorTypes.URL,
          protocolIdentifier: false,
          message: "Please enter a valid URL"
        }
      ]
    },
    {
      index: 9,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "facebookHandle",
      label: "Facebook handle",
      dataType: getDdfDataType(FieldType.Text),
      initialValue: clientData.facebookHandle,
      prefix: "facebook.com/",
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern:
            /^(?:[A-Za-z0-9]+(?:[._][A-Za-z0-9]+)*|profile\.php\?id=\d+|people\/[A-Za-z0-9-]+\/\d+)$/,
          message: "Please enter a valid Facebook handle"
        }
      ]
    },
    {
      index: 10,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "instagramHandle",
      label: "Instagram handle",
      dataType: getDdfDataType(FieldType.Text),
      initialValue: clientData.instagramHandle,
      prefix: "instagram.com/",
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: /^@?([A-Za-z0-9_.]){1,29}$/,
          message: "Please enter a valid Instagram handle"
        }
      ]
    },
    {
      index: 11,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "twitterHandle",
      label: "X/Twitter handle",
      dataType: getDdfDataType(FieldType.Text),
      initialValue: clientData.twitterHandle,
      prefix: "twitter.com/",
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: /^@?([A-Za-z0-9_]){1,14}$/,
          message: "Please enter a valid X/Twitter handle"
        }
      ]
    },
    {
      index: 12,
      component: "section-header",
      name: "address",
      title: "Address",
      description:
        "Address details will be included in your site footer and emails sent to your users."
    },
    {
      index: 13,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "address.address1",
      label: "Address line 1",
      dataType: getDdfDataType(FieldType.Text),
      initialValue: clientData.address?.address1
    },
    {
      index: 14,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "address.address2",
      label: "Address line 2",
      dataType: getDdfDataType(FieldType.Text),
      initialValue: clientData.address?.address2
    },
    {
      index: 16,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "address.city",
      label: "City",
      dataType: getDdfDataType(FieldType.Text),
      initialValue: clientData.address?.city
    },
    ...(["US", "AU"].includes(clientData.country)
      ? [
          {
            index: 16,
            arrayField: false,
            component: getFormComponentFromFieldType(FieldType.SelectList),
            name: "address.state",
            label: "State",
            dataType: getDdfDataType(FieldType.SelectList),
            options: getStatesList(clientData.country),
            initialValue: clientData.address?.state
          }
        ]
      : []),
    ...(clientData.country === "US"
      ? [
          {
            index: 17,
            arrayField: false,
            component: getFormComponentFromFieldType(FieldType.Text),
            name: "address.postalCode",
            label: "ZIP Code",
            dataType: getDdfDataType(FieldType.Text),
            initialValue: clientData.address?.postalCode,
            validate: [
              {
                type: "pattern",
                pattern: /^\d{5}(-\d{4})?$/,
                message: "Please enter a valid ZIP code"
              }
            ]
          }
        ]
      : [
          {
            index: 17,
            arrayField: false,
            component: getFormComponentFromFieldType(FieldType.Text),
            name: "address.postalCode",
            label: "Postal code",
            dataType: getDdfDataType(FieldType.Text),
            initialValue: clientData.address?.postalCode,
            ...(clientData.country === "AU" && {
              validate: [
                {
                  type: "pattern",
                  pattern: /^\d{4}$/,
                  message: "Please enter a valid Postal code"
                }
              ]
            })
          }
        ]),
    {
      index: 18,
      component: "section-header",
      name: "financialDocumentsSectionHeader",
      title: "Financial documents",
      description:
        "Configure settings for invoices, receipts and statements sent to your users."
    },
    {
      index: 18,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Textarea),
      dataType: getDdfDataType(FieldType.Textarea),
      name: "financialDocuments.headerMessage",
      label: "Header message",
      helpText:
        "This information will be included in the header of your invoices, receipts and statements.",
      initialValue: clientData.financialDocuments?.headerMessage,
      shouldUseRichText: false
    },
    {
      index: 18,
      component: "section-header",
      name: "locale",
      title: "Locale & currency",
      description: "Please contact us if you need these settings to be changed."
    },
    {
      index: 19,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "timeZone",
      label: "Time zone",
      disabled: true,
      helpText: null,
      isRequired: true,
      dataType: getDdfDataType(FieldType.Text),
      validate: [
        {
          type: "required"
        }
      ],
      initialValue: getTimeZoneDisplayFormat(
        timeZones.find(
          timeZone => timeZone.name === clientData.timeZone
        ) as TimeZone
      )
    },
    {
      index: 20,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "country",
      label: "Country",
      disabled: true,
      helpText: null,
      isRequired: true,
      dataType: getDdfDataType(FieldType.Text),
      validate: [
        {
          type: "required"
        }
      ],
      initialValue: clientData.country ? clientData.country.toUpperCase() : "GB"
    },
    {
      index: 21,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Text),
      name: "currency",
      label: "Currency",
      disabled: true,
      helpText: null,
      isRequired: true,
      dataType: getDdfDataType(FieldType.Text),
      validate: [
        {
          type: "required"
        }
      ],
      initialValue: clientData.currency.toUpperCase()
    }
  ];

  return { fields: [...fields] };
};

export const generateWaitlistSettingsFormSchema = (
  clientData: Client
): Schema => {
  const start = clientData?.waitlists?.notificationWindow?.start || {
    hours: 8,
    minutes: 0
  };
  const end = clientData?.waitlists?.notificationWindow?.end || {
    hours: 20,
    minutes: 0
  };

  // Custom validator function
  const validateTimeRange: Validator = (_, allValues?: object | undefined) => {
    const values = allValues as {
      waitlists: {
        notificationWindowStart: string;
        notificationWindowEnd: string;
      };
    };

    if (
      !allValues ||
      !values.waitlists ||
      !values.waitlists.notificationWindowStart ||
      !values.waitlists.notificationWindowEnd
    ) {
      return undefined;
    }

    const start = values.waitlists.notificationWindowStart;
    const end = values.waitlists.notificationWindowEnd;

    if (start && end) {
      const startMinutes = timeToMinutes(start);
      const endMinutes = timeToMinutes(end);

      if (startMinutes >= endMinutes) {
        return "Start time must be before end time";
      }
    }

    return undefined;
  };

  const fields = [
    {
      index: 0,
      component: "section-header",
      name: "waitlistSettings",
      title: "Global waitlist settings"
    },
    {
      index: 1,
      arrayField: false,
      component: "duration",
      name: "waitlists.expiryTimeForPlaceOffered",
      label: "Expiry time for place offered",
      helpText: "Set the time limit for users to accept an offered place",
      isRequired: true,
      validate: [
        (value: { hours: string; minutes: string }) => {
          const hoursValue = parseInt(value?.hours, 10);
          const minutesValue = parseInt(value?.minutes, 10);
          const totalMinutes = convertToMinutes(hoursValue, minutesValue);

          if (isNaN(hoursValue) && isNaN(minutesValue)) {
            return "If restricting the expiry time, a valid time should be specified.";
          } else if (hoursValue > 99) {
            return "Hours value should not exceed 99.";
          } else if (minutesValue > 59) {
            return "Minutes value should not exceed 59.";
          } else if (totalMinutes < 1) {
            return "Expiry time must be at least 1 minute.";
          }
        }
      ],
      disabled: !getIsClientPremiumPlanOrHigher(clientData),
      initialValue: convertToHours(
        clientData.waitlists?.expiryTimeForPlaceOffered || 1440
      )
    },
    {
      index: 2,
      arrayField: false,
      component: componentTypes.SWITCH,
      name: "waitlists.setNotificationWindow",
      label: "Enable notification window",
      switchLabel: "Set specific time window for notifications",
      helpText:
        "Choose whether to set a specific time window for sending notifications",
      isRequired: true,
      dataType: dataTypes.BOOLEAN,
      isDisabled: !getIsClientPremiumPlanOrHigher(clientData),
      initialValue: clientData.waitlists?.setNotificationWindow ?? true
    },
    {
      index: 3,
      arrayField: false,
      component: componentTypes.TIME_PICKER,
      name: `waitlists.notificationWindowStart`,
      label: "Notification window start time",
      condition: { when: "waitlists.setNotificationWindow", is: true },
      validate: [{ type: "required" }, validateTimeRange],
      disabled: !getIsClientPremiumPlanOrHigher(clientData),
      initialValue: `${padWithZero(start.hours)}:${padWithZero(start.minutes)}`
    },
    {
      index: 4,
      arrayField: false,
      component: componentTypes.TIME_PICKER,
      name: `waitlists.notificationWindowEnd`,
      label: "Notification window end time",
      condition: { when: "waitlists.setNotificationWindow", is: true },
      validate: [{ type: "required" }, validateTimeRange],
      disabled: !getIsClientPremiumPlanOrHigher(clientData),
      initialValue: `${padWithZero(end.hours)}:${padWithZero(end.minutes)}`
    }
  ];

  const schema = {
    fields: [...fields]
  };

  return schema;
};

interface GeneratePaymentMethodSettingsFormSchemaData {
  paymentMethodInstance: PaymentMethodInstance;
  stripeConnectedAccounts?: StripeConnectedAccountWithStripeAccount[];
  clientHasActiveSubscriptions?: boolean;
  ccvProviders?: CcvProvider[];
  ccvProvidersUsedCheck?: IdsHash;
  client: Client;
}

export const generatePaymentMethodSettingsFormSchema = ({
  paymentMethodInstance,
  stripeConnectedAccounts,
  clientHasActiveSubscriptions,
  ccvProviders,
  ccvProvidersUsedCheck,
  client
}: GeneratePaymentMethodSettingsFormSchemaData): Schema => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "name",
      label: "Display name",
      isRequired: true,
      validate: [{ type: "required" }],
      initialValue: paymentMethodInstance.name
    },
    ...(isStripePaymentMethod(paymentMethodInstance)
      ? [
          {
            index: 1,
            component: "stripe-connection",
            name: "stripeConnection",
            label: "Stripe connection",
            stripeConnectedAccounts,
            clientHasActiveSubscriptions,
            paymentMethodId: paymentMethodInstance._id,
            client
          }
        ]
      : []),
    ...(!isStripePaymentMethod(paymentMethodInstance)
      ? [
          {
            index: 1,
            arrayField: false,
            component: getFormComponentFromFieldType(FieldType.Textarea),
            dataType: getDdfDataType(FieldType.Textarea),
            name: "message",
            label: "Instructions",
            helpText:
              "Displayed to the user on the payment page and in their booking confirmation email.",
            initialValue: paymentMethodInstance.message,
            shouldIncludeLinkOptions: true,
            shouldIncludeMediaOptions: false
          },
          {
            index: 2,
            arrayField: false,
            component: componentTypes.CHECKBOX,
            name: "enabled",
            label: "Enabled",
            initialValue: paymentMethodInstance.enabled,
            ...((isCcvPaymentMethod(paymentMethodInstance) ||
              isTaxFreeChildcarePaymentMethod(paymentMethodInstance)) &&
              client.planLevel < 20 && {
                isDisabled: true,
                helpText:
                  "Sorry, this payment method can only be enabled on the Standard and Premium plans."
              })
          }
        ]
      : []),
    ...(isCcvPaymentMethod(paymentMethodInstance)
      ? [
          {
            index: 3,
            component: "section-header",
            name: "ccvProvidersHeader",
            title: "Childcare Voucher Providers"
          },
          {
            index: 4,
            component: "ccv-providers",
            name: "ccvProviders",
            label: "Childcare Voucher",
            required: true,
            validate: [
              {
                type: "required",
                message: "Please add at least one Childcare Voucher Provider"
              }
            ],
            arrayField: true,
            initialValue: ccvProviders,
            ccvProvidersUsedCheck
          }
        ]
      : [])
  ];

  const schema = {
    fields
  };

  return schema;
};

export const generateBookingConfirmationEmailSettingsFormSchema = (
  bookingConfirmationEmail: Email
): Schema => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "subject",
      label: "Subject",
      isRequired: true,
      validate: [{ type: "required" }],
      helpText: "Subject line sent on the email when a new booking is created.",
      initialValue: bookingConfirmationEmail.subject
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "subjectForUpdate",
      label: "Subject when updated",
      isRequired: true,
      validate: [{ type: "required" }],
      helpText:
        "Subject line sent on the email when an existing booking is updated.",
      initialValue: bookingConfirmationEmail.subjectForUpdate
    },
    {
      index: 2,
      arrayField: false,
      component: componentTypes.TEXTAREA,
      name: "msg",
      label: "Message",
      isRequired: true,
      validate: [{ type: "required" }],
      helpText:
        "Message will be displayed above the details in the booking confirmation email sent to users.",
      initialValue: bookingConfirmationEmail.msg,
      shouldIncludeLinkOptions: true,
      shouldIncludeMediaOptions: false
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

export const generateAdminBookingNotificationEmailSettingsFormSchema = (
  adminBookingNotificationEmail: Email
): Schema => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "subject",
      label: "Subject",
      isRequired: true,
      validate: [{ type: "required" }],
      helpText: "Subject line sent on the email when a new booking is placed.",
      initialValue: adminBookingNotificationEmail.subject
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "subjectForUpdate",
      label: "Subject when updating",
      isRequired: true,
      validate: [{ type: "required" }],
      helpText:
        "Subject line sent on the email when an existing booking is placed.",
      initialValue: adminBookingNotificationEmail.subjectForUpdate
    },
    {
      index: 2,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "recipientAddress",
      label: "Recipient address",
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED
        },
        {
          type: validatorTypes.PATTERN,
          pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
          message: "Not a valid email address"
        }
      ],
      helpText: "The address the email will be sent to.",
      initialValue: adminBookingNotificationEmail.recipientAddress
    },
    {
      index: 3,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "enabled",
      label: "Enabled",
      initialValue: adminBookingNotificationEmail.enabled
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

export const generateAdminSubscriptionNotificationEmailSettingsFormSchema = (
  adminSubscriptionNotificationEmail: Email
): Schema => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "recipientAddress",
      label: "Recipient address",
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED
        },
        {
          type: validatorTypes.PATTERN,
          pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
          message: "Not a valid email address"
        }
      ],
      helpText: "The address the email will be sent to.",
      initialValue: adminSubscriptionNotificationEmail.recipientAddress
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "enabled",
      label: "Enabled",
      initialValue: adminSubscriptionNotificationEmail.enabled
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

export const generateAbandonedCartEmailSettingsFormSchema = (
  abandonedCartEmail: Email,
  client: Client
): Schema => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "subject",
      label: "Subject",
      isRequired: true,
      validate: [{ type: "required" }],
      helpText: "Subject line sent on the email.",
      initialValue: abandonedCartEmail.subject
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXTAREA,
      name: "msg",
      label: "Message",
      isRequired: true,
      validate: [{ type: "required" }],
      helpText:
        "Message will be displayed above a button to complete the booking.",
      initialValue: abandonedCartEmail.msg,
      shouldIncludeLinkOptions: true,
      shouldIncludeMediaOptions: false
    },
    {
      index: 2,
      arrayField: false,
      component: "range",
      name: "sendTime",
      label: "Send time",
      isRequired: true,
      validate: [{ type: "required" }],
      helpText:
        "Email will be sent after a booking has been left abandoned for this amount of time.",
      initialValue: abandonedCartEmail.sendTime
    },
    {
      index: 3,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "enabled",
      label: "Enabled",
      initialValue: abandonedCartEmail.enabled,
      ...(client.planLevel < 30 && {
        isDisabled: true,
        helpText: "Sorry, this email can only be enabled on the Premium plan."
      })
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

export const generateActivityTicketFormSchema = ({
  formTemplate = FormTemplateType.Default,
  prefix,
  editingTicket,
  subscriptionPlansData,
  allTicketsData,
  client
}: GenerateActivityTicketFormSchemaData) => {
  const specifyTicketLimitInitialValue = Boolean(editingTicket?.placeLimit);
  const overrideSessionTimeDisplayInitialValue = Boolean(
    editingTicket?.timeDisplay?.start && editingTicket?.timeDisplay?.end
  );

  const showAdvancedOptionsInitialValue = Boolean(
    specifyTicketLimitInitialValue ||
      overrideSessionTimeDisplayInitialValue ||
      editingTicket?.restrictSessions
  );
  const subscriptionPlansIdsUsedByOtherTickets = allTicketsData
    .filter(
      ticket =>
        ticket.type === TicketType.Subscription &&
        ticket._id !== editingTicket?._id
    )
    .map(ticket => ticket.subscriptionPlan?._id);

  const fields = [
    {
      index: 0,
      arrayField: false,
      component: "radio-group-with-description",
      name: `${prefix}.type`,
      label: "Type",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      options: [
        {
          value: TicketType.All,
          label: "All sessions",
          description: "Books an attendee in to all of the activity's sessions."
        },
        {
          value: TicketType.Single,
          label: "Single session",
          description:
            "Books an attendee in to a single session. Users will be able to choose the session(s) they wish to book."
        },
        {
          value: TicketType.Subscription,
          label: "Subscription",
          description:
            "Books an attendee in to all of the activity's sessions through a monthly subscription."
        }
      ],
      initialValue: editingTicket?.type,
      isDisabled: Boolean(
        editingTicket?._id && !editingTicket?._id.includes("temp_")
      )
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: `${prefix}.name`,
      label: "Ticket name",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      initialValue: editingTicket?.name
    },
    {
      index: 2,
      arrayField: false,
      component: "currency",
      name: `${prefix}.price`,
      label: "Price",
      isRequired: true,
      formTemplate,
      // TODO: may want to change the validation and initial value stuff to be the same as add-ons as this seems to better handles 0 values
      validate: [
        { type: "required" },
        (value: string) => {
          const valueParsed = parseInt(value, 10);
          return valueParsed < 0 ? "Price cannot be negative" : undefined;
        }
      ],
      initialValue: editingTicket?.price
        ? getCurrencyAmountForDisplay(editingTicket.price, client.currency)
        : null,
      client,
      condition: {
        or: [
          { when: `${prefix}.type`, is: TicketType.All },
          {
            when: `${prefix}.type`,
            is: TicketType.Single
          }
        ]
      }
    },
    {
      index: 3,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.CheckboxSingle),
      isRequired: false,
      formTemplate,
      name: `${prefix}.shouldProRata`,
      label: "Pro rata",
      dataType: getDdfDataType(FieldType.CheckboxSingle),
      condition: {
        when: `${prefix}.type`,
        is: TicketType.All
      },
      helpText:
        "If checked, the price will be reduced 'pro rata' based on the number of enabled sessions remaining.",
      initialValue: editingTicket?.shouldProRata
    },
    {
      index: 4,
      arrayField: false,
      component: "header-show-hide",
      name: `${prefix}.showAdvancedOptions`,
      label: "Advanced options",
      onLabel: "Hide advanced options",
      offLabel: "Show advanced options",
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      formTemplate,
      initialValue: showAdvancedOptionsInitialValue
    },
    {
      index: 5,
      arrayField: false,
      component: componentTypes.SWITCH,
      name: `${prefix}.specifyTicketLimit`,
      label: "Specify ticket limit",
      switchLabel: "Limit sales using this ticket",
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      formTemplate,
      initialValue: specifyTicketLimitInitialValue,
      condition: {
        when: `${prefix}.showAdvancedOptions`,
        is: true
      }
    },
    {
      index: 6,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: `${prefix}.placeLimit`,
      label: "Ticket limit",
      inputType: "number",
      isRequired: false,
      validate: [
        (value: string) => {
          const valueParsed = parseInt(value, 10);
          return valueParsed < 1
            ? "If specifying a ticket limit it must be at least 1"
            : undefined;
        }
      ],
      formTemplate,
      condition: [
        {
          when: `${prefix}.showAdvancedOptions`,
          is: true
        },
        {
          when: `${prefix}.specifyTicketLimit`,
          is: true
        }
      ],
      marginTop: 2,
      initialValue: editingTicket?.placeLimit
    },
    {
      index: 7,
      arrayField: false,
      component: componentTypes.SWITCH,
      name: `${prefix}.restrictSessions`,
      label: "Restrict sessions",
      switchLabel: "Restrict ticket to specific sessions",
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      formTemplate,
      condition: [
        {
          when: `${prefix}.type`,
          is: TicketType.Single
        },
        {
          when: `${prefix}.showAdvancedOptions`,
          is: true
        }
      ],
      initialValue: editingTicket?.restrictSessions
    },
    {
      index: 8,
      arrayField: false,
      component: "session-options",
      name: `${prefix}.sessionsCanBeUsedFor`,
      label: "Sessions ticket can be used for",
      isRequired: true,
      client: client,
      validate: [
        {
          type: "required",
          message:
            "If restricting the sessions the ticket can be used for, at least one should be selected."
        }
      ],
      formTemplate,
      condition: [
        {
          when: `${prefix}.showAdvancedOptions`,
          is: true
        },
        {
          when: `${prefix}.restrictSessions`,
          is: true
        }
      ],
      marginTop: 2,
      initialValue: editingTicket?.sessionsCanBeUsedFor
    },
    {
      index: 7,
      arrayField: false,
      component: componentTypes.SELECT,
      isRequired: true,
      validate: [
        {
          type: "required",
          message:
            "If creating a subscription ticket, a subscription plan must be selected."
        }
      ],
      name: `${prefix}.subscriptionPlanId`,
      label: "Subscription plan",
      formTemplate,
      options: subscriptionPlansData
        .filter(
          subscriptionPlan =>
            !subscriptionPlansIdsUsedByOtherTickets.includes(
              subscriptionPlan._id
            ) &&
            (subscriptionPlan._id === editingTicket?.subscriptionPlan?._id ||
              getCanSubscriptionPlanBeAddedToNewTicket(subscriptionPlan))
        )
        .map(subscriptionPlan => ({
          value: subscriptionPlan._id,
          label: subscriptionPlan.enabled
            ? subscriptionPlan.name
            : `${subscriptionPlan.name} (disabled)`
        })),
      condition: {
        when: `${prefix}.type`,
        is: TicketType.Subscription
      },
      initialValue: editingTicket?.subscriptionPlan?._id,
      disabled: Boolean(
        editingTicket?._id && !editingTicket?._id.includes("temp_")
      )
    },
    {
      index: 8,
      arrayField: false,
      component: componentTypes.SWITCH,
      name: `${prefix}.allowMidMonthBookings`,
      label: "Allow mid-month bookings",
      switchLabel: "Allow mid-month bookings",
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      formTemplate,
      initialValue: editingTicket?.allowMidMonthBookings,
      helpText:
        "Allow attendees join partway through a month, even though subscription payments are collected on the 1st of each month.",
      condition: {
        when: `${prefix}.type`,
        is: TicketType.Subscription
      }
    },
    {
      index: 8,
      arrayField: false,
      component: "currency",
      name: `${prefix}.coverFeePerSession`,
      label: "Cover fee per session",
      isRequired: true,
      formTemplate,
      validate: [
        { type: "required" },
        (value: string) => {
          const valueParsed = parseInt(value, 10);

          return valueParsed < 0 ? "Fee cannot be negative" : undefined;
        }
      ],
      initialValue: editingTicket?.coverFeePerSession
        ? getCurrencyAmountForDisplay(
            editingTicket.coverFeePerSession,
            client.currency
          )
        : null,
      client,
      condition: {
        when: `${prefix}.allowMidMonthBookings`,
        is: true
      },
      helpText:
        "The cover fee to charge per session if joining partway through a month."
    },
    {
      index: 9,
      arrayField: false,
      component: componentTypes.SWITCH,
      name: `${prefix}.overrideSessionTimeDisplay`,
      label: "Override session time display",
      switchLabel: "Override session time display",
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      formTemplate,
      condition: [
        {
          when: `${prefix}.type`,
          is: TicketType.Single
        },
        {
          when: `${prefix}.showAdvancedOptions`,
          is: true
        }
      ],
      initialValue: overrideSessionTimeDisplayInitialValue
    },
    {
      index: 10,
      arrayField: false,
      component: componentTypes.TIME_PICKER,
      name: `${prefix}.overrideStartTime`,
      label: "Start time",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      condition: [
        {
          when: `${prefix}.showAdvancedOptions`,
          is: true
        },
        {
          when: `${prefix}.overrideSessionTimeDisplay`,
          is: true
        }
      ],
      marginTop: 2,
      initialValue: editingTicket?.timeDisplay?.start
        ? `${padNumber(editingTicket.timeDisplay.start.hours)}:${padNumber(
            editingTicket.timeDisplay.start.minutes
          )}`
        : ""
    },
    {
      index: 11,
      arrayField: false,
      component: componentTypes.TIME_PICKER,
      name: `${prefix}.overrideEndTime`,
      label: "End time",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      condition: [
        {
          when: `${prefix}.showAdvancedOptions`,
          is: true
        },
        {
          when: `${prefix}.overrideSessionTimeDisplay`,
          is: true
        }
      ],
      marginTop: 2,
      initialValue: editingTicket?.timeDisplay?.end
        ? `${padNumber(editingTicket.timeDisplay.end.hours)}:${padNumber(
            editingTicket.timeDisplay.end.minutes
          )}`
        : ""
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

export const generateActivityAddOnFormSchema = ({
  formTemplate = FormTemplateType.Default,
  prefix,
  editingAddOn,
  client
}: GenerateActivityAddOnFormSchemaData) => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: `${prefix}.name`,
      label: "Name",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      initialValue: editingAddOn?.name
    },
    {
      index: 1,
      arrayField: false,
      component: "currency",
      name: `${prefix}.price`,
      label: "Price",
      isRequired: true,
      formTemplate,
      validate: [
        // { type: 'required' },
        (value: string | number) => {
          if (!value && value !== 0) {
            return "Price is required";
          }

          const valueParsed = parseInt(String(value), 10);

          return valueParsed < 0 ? "Price cannot be negative" : undefined;
        }
      ],
      initialValue:
        editingAddOn?.price || editingAddOn?.price === 0
          ? getCurrencyAmountForDisplay(editingAddOn.price, client.currency)
          : null,
      client
    },

    {
      index: 7,
      arrayField: false,
      component: componentTypes.SWITCH,
      name: `${prefix}.restrictSessions`,
      label: "Restrict sessions",
      switchLabel: "Restrict add-on to specific sessions",
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      formTemplate,
      initialValue: editingAddOn?.restrictSessions
    },
    {
      index: 8,
      arrayField: false,
      component: "session-options",
      name: `${prefix}.sessionsCanBeUsedFor`,
      label: "Sessions add-on can be used for",
      isRequired: true,
      client: client,
      validate: [
        {
          type: "required",
          message:
            "If restricting the sessions the add-on can be used for, at least one should be selected."
        }
      ],
      formTemplate,
      condition: [
        {
          when: `${prefix}.restrictSessions`,
          is: true
        }
      ],
      marginTop: 2,
      initialValue: editingAddOn?.sessionsCanBeUsedFor
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

export const generateBookingAgreementFormSchema = (
  usage: AgreementUsage,
  bookingAgreement?: Agreement
): Schema => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "name",
      label: "Name",
      isRequired: true,
      validate: [{ type: "required" }],
      helpText: `Short name of the agreement. E.g. ${
        usage === AgreementUsage.Booking
          ? "Cancellation policy"
          : "Terms and conditions"
      }`,
      initialValue: bookingAgreement?.name
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "text",
      label: "Agreement text",
      isRequired: true,
      useWarnings: true,
      multiLine: true,
      validate: [
        { type: "required" },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 128,
          message:
            "We suggest keeping the Agreement text brief to ensure the best user experience. Consider linking to a policy below.",
          warning: true
        }
      ],
      initialValue: bookingAgreement?.text,
      helpText:
        "If linking to a policy, a <link> tag should be included to link to it. E.g. <link>cancellation policy</link>"
    },
    {
      index: 2,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.CheckboxSingle),
      name: "linkToPolicy",
      label: "Link to policy",
      dataType: getDdfDataType(FieldType.CheckboxSingle),
      helpText:
        "An optional policy statement to include more details about this agreement.",
      initialValue: bookingAgreement?.linkToPolicy
    },
    {
      index: 3,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.Textarea),
      name: "policyText",
      label: "Policy text",
      dataType: getDdfDataType(FieldType.Textarea),
      condition: {
        when: "linkToPolicy",
        is: true
      },
      initialValue: bookingAgreement?.policyText,
      shouldIncludeLinkOptions: true,
      shouldIncludeMediaOptions: false
    },
    {
      index: 4,
      arrayField: false,
      component: getFormComponentFromFieldType(FieldType.CheckboxSingle),
      name: "includePolicyInSiteFooter",
      label: "Include policy in site footer",
      dataType: getDdfDataType(FieldType.CheckboxSingle),
      condition: {
        when: "linkToPolicy",
        is: true
      },
      initialValue: bookingAgreement?.includePolicyInSiteFooter
    },

    {
      index: 5,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "enabled",
      label: "Enabled",
      dataType: dataTypes.BOOLEAN,
      initialValue: bookingAgreement ? bookingAgreement.enabled : true
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

export const getMessageFromAxiosErrorResponse = (
  axiosError: AxiosError
): string => {
  const response = axiosError.response;

  let message: string;

  switch (response?.statusText) {
    case "Unauthorized":
      message = "Sorry there was a problem logging you in.";
      break;
    default:
      message = "Sorry there was an error with your form submission";
      break;
  }

  return message;
};

export const validateCcvPaymentMethodEditForm = (
  values: FormValues
): FormErrors => {
  const errors: FormErrors = {};

  const hasEnabledPaymentMethod = values.ccvProviders?.some(
    (ccvProvider: { enabled: boolean }) => ccvProvider.enabled
  );

  if (values.enabled && !hasEnabledPaymentMethod) {
    errors.ccvProviders =
      "At least one Childcare Voucher Provider must be enabled if you wish to enable this payment method.";
  }

  return errors;
};

export const validatePasswordsMatch = (values: FormValues): FormErrors => {
  const errors: FormErrors = {};
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match.";
  }
  return errors;
};

export const validateBookingAgreementForm = (
  values: FormValues
): FormErrors => {
  const errors: FormErrors = {};

  const policyLinks = values.text?.match(/<link>(.*?)<\/link>/gi);

  if (values.linkToPolicy && !policyLinks) {
    errors.text =
      "As you are linking this agreement to a policy, a <link> tag must be included. E.g. <link>cancellation policy</link>";
  }

  return errors;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDataArrayReplacedWithTempIds = <T>(data: T[]): T[] => {
  return data.map(item => {
    const _id = `temp_${Math.random().toString(36).substring(7)}`;
    return {
      ...item,
      _id,
      id: _id
    };
  });
};
